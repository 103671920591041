
import {
  apiFaceSheetTemplateLists,
  apiFaceSheetTemplateDel,
} from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
  components: {
    LsDialog,
    LsPagination,
  },
})
export default class FaceSheet extends Vue {
  // 分页
  pager: RequestPaging = new RequestPaging();

  // 获取列表
  getList() {
    this.pager
      .request({
        callback: apiFaceSheetTemplateLists,
        params: {},
      })
      .catch(() => {
        this.$message.error("数据请求失败，刷新重载!");
      });
  }

  // 删除这个打印机
  onDel(id: any) {
    apiFaceSheetTemplateDel({ id: id })
      .then(() => {
        // 删除成功就请求新列表
        this.getList();
      })
      .catch(() => {
        this.$message.error("删除失败!");
      });
  }

  // 新增
  goFacesheetAdd() {
    this.$router.push({
      path: "/logistics/facesheet_edit",
      query: {
        mode: PageMode.ADD,
      },
    });
  }

  // 编辑
  goFacesheetEdit(id: any) {
    this.$router.push({
      path: "/logistics/facesheet_edit",
      query: {
        id: id,
        mode: PageMode.EDIT,
      },
    });
  }

  created() {
    this.getList();
  }
}
